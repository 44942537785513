body {
  font-size: 1em;
}

p {
  font-size: 20px;
}

.form {
  max-width: 500px;
}

.formGroup {
  margin-bottom: 15px;
}

.App {
  text-align: center;
}

.inlineGuidance {
  font-style: italic;
}

.subtext {
  font-style: italic;
}

.content {
  padding-bottom: 50px;
}

.cookie-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
}

.cookie-banner p {
  margin: 0;
  flex: 1;
}

.cookie-banner button {
  margin-left: 10px;
  margin-right: 20px;
  padding: 5px 15px;
}

.footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #F8F9FA;
  padding:5px;
  margin-top:30px;
}

.innerContent {
  text-align: left;
}

.toolsheetContent {
  padding-left: 15%;
  padding-right: 15%;
}
@media (max-width: 768px) {
  .toolsheetContent {
      padding-left: 5%;
      padding-right: 5%;
  }
}

.number-section {
  margin-bottom: 15px;
}

.chosen {
  color:green;
}

.notchosen {
  color:blue;
}

.chartContainer {
  display: flex;
  justify-content: center;
}

.chartRow {
  text-align: center;
  display:grid;
  grid-template-columns: repeat(13, 36px);
}

.chartHeaderRow {
  padding-top: 5px;
}

.chartElement {
  display: inline-block;
  height: 32px;
  width: 32px;
  line-height: 32px;
  margin:2px;
  background-color: lightgray;
  z-index: 2;
}

.chartRowHeaderElement {
  background-color: lightblue;
}

.chartRowHeaderElementSelected {
  background-color: gold;
}

.chartRowHeaderElementSelectedMatch {
  background-color: gold;
  font-weight: bold;
}

.chartCornerElement {
  background-color: transparent;
  color: transparent;
}

.chartElementSelected {
  text-decoration: underline;
  background-color: gold;
  font-weight: bold;
}

.chartElementSelectedRow {
  background-color: gold;
}

.chartElementHoveredSelect {
  font-weight: bold;
}

.chartElementHovered {
  font-weight: bold;
}

.pairRow {
  text-align: center;
}

.pairElement {
  display: inline-block;
  height: 64px;
  width: 64px;
  line-height: 64px;
  margin:4px;
  background-color: aliceblue;
  font-size: 36px;
}

.pairNonElement {
  background-color:transparent;
}

.response {
  outline: 0;
  border-width: 0 0 2px;
  border-color: darkgray;
  display: inline-block;
  height: 128px;
  width: 200px;
  line-height: 128px;
  margin:12px;
  font-size: 100px;
  text-align: center;
}

.pointCounter {
  font-size: 42px;
  font-weight: bold;
  margin-bottom: -8px;
}

.statsContainer {
  display: flex;
  justify-content: center;
}

.statsRow {
  text-align: center;
  display:grid;
  grid-template-columns: repeat(3, 84px);
}

.statsRowElement {
  display: inline-block;
  height: 32px;
  width: 80px;
  line-height: 32px;
  margin:2px;
}

.statsRowHeader {
  background-color: lightgray;
}

.statsRowValue {
  background-color: transparent;
}

.spacer {
  padding-top: 15px;
}

.spacerSmall {
  padding-top: 7px;
}

.conclusionDiv {
  padding-top: 15px;
}

.showBlock {
  display: block;
}

.noShow {
  display: none;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
}

.overlayText {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}

.quizIntro {
  padding-top: 15px;
}

.scoreBoard {
  display: inline-block;
  text-align: center;
  justify-content: center;
  border: 2px solid lightgrey;
  padding: 7px;
}

.scoreBoardComponentLeft {
  width:200px;
  padding-right:10px;
}

.scoreBoardComponentRight {
  width:200px;
  padding-left:10px;
}

.circle {
  background-color: blue;
  color: white;
  border-radius: 50%; 
  width: 32px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@keyframes flash {
  0% {opacity: 1;}
  50% {opacity: 0;}
  100% {opacity: 1;}
}

.flash {
  animation: flash 1s linear;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* When the screen is 480px or less, adjust the cell size */
@media (max-width: 480px) {
  .chartRow {
    grid-template-columns: repeat(13, 28px); /* Smaller cell size for smaller screens */
  }

  .chartElement {
    height: 24px;
    width: 24px;
    line-height: 24px;
    font-size: 14px;
  }

  .pairElement {
    height: 48px;
    width: 48px;
    line-height: 48px;
    font-size: 27px;
  }

  .list-group {
    margin-bottom: 15px;
  }

  .card {
    margin-bottom: 15px;
  }
}